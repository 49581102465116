var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      title: _vm.$t("Criar nova transferência"),
      show: _vm.showSidebar,
      fetching: _vm.loading,
      saving: _vm.saving,
      width: "500px",
    },
    on: { save: _vm.saveAccountTransfer, hidden: _vm.onCloseSidebar },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              {
                ref: "formAccountTransfer",
                attrs: { name: "formAccountTransfer" },
                on: { submit: _vm.saveAccountTransfer },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          attrs: {
                            id: "account_transfer-store_origin",
                            label: _vm.$t("Loja origem"),
                            required: true,
                            "select-single-option": false,
                          },
                          on: {
                            input: function ($event) {
                              _vm.formAccountTransfer.checkingAccountOriginId =
                                null
                            },
                          },
                          model: {
                            value: _vm.formAccountTransfer.storeOriginId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "storeOriginId",
                                $$v
                              )
                            },
                            expression: "formAccountTransfer.storeOriginId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("e-checking-account-combo", {
                          attrs: {
                            id: "account_transfer-checking_account_origin",
                            label: _vm.$t("Conta corrente origem"),
                            type: ["Main", "Internal"],
                            required: true,
                            "store-id": _vm.formAccountTransfer.storeOriginId,
                            "active-options": "",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.fetchAccountBalance("origin")
                            },
                          },
                          model: {
                            value:
                              _vm.formAccountTransfer.checkingAccountOriginId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "checkingAccountOriginId",
                                $$v
                              )
                            },
                            expression:
                              "formAccountTransfer.checkingAccountOriginId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.originBalance
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "account_transfer-origin_balance",
                                type: "label",
                                filter: "currency",
                                label: _vm.$t("Saldo atual da Conta Origem"),
                              },
                              model: {
                                value: _vm.originBalance,
                                callback: function ($$v) {
                                  _vm.originBalance = $$v
                                },
                                expression: "originBalance",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          attrs: {
                            id: "account_transfer-store_destination",
                            label: _vm.$t("Loja destino"),
                            "select-single-option": false,
                            required: true,
                          },
                          on: {
                            input: function ($event) {
                              _vm.formAccountTransfer.checkingAccountDestinationId =
                                null
                            },
                          },
                          model: {
                            value: _vm.formAccountTransfer.storeDestinationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "storeDestinationId",
                                $$v
                              )
                            },
                            expression:
                              "formAccountTransfer.storeDestinationId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("e-checking-account-combo", {
                          attrs: {
                            id: "account_transfer-checking_account_destination",
                            label: _vm.$t("Conta corrente destino"),
                            type: ["Main", "Internal"],
                            required: true,
                            "store-id":
                              _vm.formAccountTransfer.storeDestinationId,
                            "active-options": "",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.fetchAccountBalance("destination")
                            },
                          },
                          model: {
                            value:
                              _vm.formAccountTransfer
                                .checkingAccountDestinationId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "checkingAccountDestinationId",
                                $$v
                              )
                            },
                            expression:
                              "formAccountTransfer.checkingAccountDestinationId",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.destinationBalance
                      ? _c(
                          "b-col",
                          { attrs: { md: "12" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "account_transfer-destination_balance",
                                type: "label",
                                filter: "currency",
                                label: _vm.$t("Saldo atual da Conta Destino"),
                              },
                              model: {
                                value: _vm.destinationBalance,
                                callback: function ($$v) {
                                  _vm.destinationBalance = $$v
                                },
                                expression: "destinationBalance",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-management-account", {
                          attrs: {
                            id: "account_transfer-management_account",
                            required: "",
                          },
                          model: {
                            value: _vm.formAccountTransfer.managementAccountId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "managementAccountId",
                                $$v
                              )
                            },
                            expression:
                              "formAccountTransfer.managementAccountId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "account_transfer-value",
                            type: "text-number",
                            currency: "R$",
                            precision: 2,
                            label: _vm.$t("Valor"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.formAccountTransfer.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.formAccountTransfer, "value", $$v)
                            },
                            expression: "formAccountTransfer.value",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "account_transfer-date",
                            type: "datepicker",
                            label: _vm.$t("Data da transferência"),
                            instruction: _vm.$t(
                              "Informar apenas se a data da transferência for diferente da data atual"
                            ),
                          },
                          model: {
                            value: _vm.formAccountTransfer.statementDate,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "statementDate",
                                $$v
                              )
                            },
                            expression: "formAccountTransfer.statementDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "account_transfer-document-type",
                            label: _vm.$t("Tipo do documento"),
                            type: "vue-select",
                            options: _vm.documentTypeOptions(),
                          },
                          model: {
                            value: _vm.formAccountTransfer.documentType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "documentType",
                                $$v
                              )
                            },
                            expression: "formAccountTransfer.documentType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-only-number-input", {
                          attrs: {
                            id: "account_transfer-document-number",
                            label: _vm.$t("N.º Documento"),
                            required: "",
                          },
                          model: {
                            value: _vm.formAccountTransfer.documentNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "documentNumber",
                                $$v
                              )
                            },
                            expression: "formAccountTransfer.documentNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "account_transfer-description",
                            type: "text",
                            label: _vm.$t("Descrição"),
                          },
                          model: {
                            value: _vm.formAccountTransfer.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formAccountTransfer,
                                "description",
                                $$v
                              )
                            },
                            expression: "formAccountTransfer.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c(
                          "b-alert",
                          {
                            staticClass: "p-2",
                            attrs: { show: "", variant: "dark-primary" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "Junto com a transaferência também são criados os registros nas respectivas contas correntes e contas a pagar e receber já baixados"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }