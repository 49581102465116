<template>
  <e-sidebar
    :title="$t('Criar nova transferência')"
    :show="showSidebar"
    :fetching="loading"
    :saving="saving"
    width="500px"
    @save="saveAccountTransfer"
    @hidden="onCloseSidebar"
  >
    <template #content>
      <FormulateForm
        ref="formAccountTransfer"
        name="formAccountTransfer"
        @submit="saveAccountTransfer"
      >
        <b-row>
          <b-col md="12">
            <!-- :select-single-option="false" é necessário para evitar loop quando usuário visualiza apenas 1 loja -->
            <e-store-combo
              id="account_transfer-store_origin"
              v-model="formAccountTransfer.storeOriginId"
              :label="$t('Loja origem')"
              :required="true"
              :select-single-option="false"
              @input="formAccountTransfer.checkingAccountOriginId = null"
            />
          </b-col>
          <b-col>
            <e-checking-account-combo
              id="account_transfer-checking_account_origin"
              v-model="formAccountTransfer.checkingAccountOriginId"
              :label="$t('Conta corrente origem')"
              :type="['Main','Internal']"
              :required="true"
              :store-id="formAccountTransfer.storeOriginId"
              active-options
              @input="fetchAccountBalance('origin')"
            />
          </b-col>
          <b-col
            v-if="originBalance"
            md="12"
          >
            <FormulateInput
              id="account_transfer-origin_balance"
              v-model="originBalance"
              type="label"
              filter="currency"
              :label="$t('Saldo atual da Conta Origem')"
            />
          </b-col>

          <b-col md="12">
            <!-- :select-single-option="false" é necessário para evitar loop quando usuário visualiza apenas 1 loja -->
            <e-store-combo
              id="account_transfer-store_destination"
              v-model="formAccountTransfer.storeDestinationId"
              :label="$t('Loja destino')"
              :select-single-option="false"
              :required="true"
              @input="formAccountTransfer.checkingAccountDestinationId = null"
            />
          </b-col>
          <b-col>
            <e-checking-account-combo
              id="account_transfer-checking_account_destination"
              v-model="formAccountTransfer.checkingAccountDestinationId"
              :label="$t('Conta corrente destino')"
              :type="['Main','Internal']"
              :required="true"
              :store-id="formAccountTransfer.storeDestinationId"
              active-options
              @input="fetchAccountBalance('destination')"
            />
          </b-col>
          <b-col
            v-if="destinationBalance"
            md="12"
          >
            <FormulateInput
              id="account_transfer-destination_balance"
              v-model="destinationBalance"
              type="label"
              filter="currency"
              :label="$t('Saldo atual da Conta Destino')"
            />
          </b-col>

          <b-col md="12">
            <e-management-account
              id="account_transfer-management_account"
              v-model="formAccountTransfer.managementAccountId"
              required
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="account_transfer-value"
              v-model="formAccountTransfer.value"
              type="text-number"
              currency="R$"
              :precision="2"
              :label="$t('Valor')"
              class="required"
              validation="required"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="account_transfer-date"
              v-model="formAccountTransfer.statementDate"
              type="datepicker"
              :label="$t('Data da transferência')"
              :instruction="
                $t('Informar apenas se a data da transferência for diferente da data atual')
              "
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              id="account_transfer-document-type"
              v-model="formAccountTransfer.documentType"
              :label="$t('Tipo do documento')"
              type="vue-select"
              :options="documentTypeOptions()"
            />
          </b-col>
          <b-col md="12">
            <e-only-number-input
              id="account_transfer-document-number"
              v-model="formAccountTransfer.documentNumber"
              :label="$t('N.º Documento')"
              required
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="account_transfer-description"
              v-model="formAccountTransfer.description"
              type="text"
              :label="$t('Descrição')"
            />
          </b-col>
          <b-col md="12">
            <b-alert
              show
              variant="dark-primary"
              class="p-2"
            >
              {{
                $t(
                  'Junto com a transaferência também são criados os registros nas respectivas contas correntes e contas a pagar e receber já baixados'
                )
              }}
            </b-alert>
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { BRow, BCol, BAlert } from 'bootstrap-vue'
import { banks, financialDomain } from '@/mixins'
import {
  ESidebar,
  EStoreCombo,
  ECheckingAccountCombo,
  EManagementAccount,
} from '@/views/components'
import EOnlyNumberInput from '@/views/components/inputs/EOnlyNumberInput.vue'

const getInitialForm = () => ({
  storeOriginId: null,
  checkingAccountOriginId: null,
  storeDestinationId: null,
  checkingAccountDestinationId: null,
  managementAccountId: null,
  value: null,
  statementDate: null,
  description: null,
  documentType: 'RECIBO',
  documentNumber: null,
})

export default {
  components: {
    BRow,
    BCol,
    ESidebar,
    EStoreCombo,
    EManagementAccount,
    ECheckingAccountCombo,
    BAlert,
    EOnlyNumberInput,
  },

  mixins: [banks, financialDomain],

  data() {
    return {
      showSidebar: false,
      saving: false,
      loading: false,
      formAccountTransfer: getInitialForm(),
      destinationBalance: null,
      originBalance: null,
    }
  },

  computed: {},

  watch: {},

  methods: {
    async onShowSidebar() {
      try {
        this.formAccountTransfer = getInitialForm()
        this.showSidebar = true
      } catch (error) {
        this.showGenericError({ error })
      } finally {
        this.loading = false
      }
    },

    onCloseSidebar() {
      this.showSidebar = false
      this.$formulate.resetValidation('formAccountTransfer')
    },

    async fetchAccountBalance(account) {
      const accountId =
        account === 'destination'
          ? this.formAccountTransfer.checkingAccountDestinationId
          : this.formAccountTransfer.checkingAccountOriginId

      if (!accountId) return
      try {
        this.loading = true
        const result = await this.$http.get(`/api/financial/checking-accounts/by-id/${accountId}`)
        const { balance } = result.data
        if (account === 'destination') {
          this.destinationBalance = balance
        } else {
          this.originBalance = balance
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.loading = false
      }
    },
    async saveAccountTransfer() {
      try {
        this.$refs.formAccountTransfer.showErrors()
        if (this.$refs.formAccountTransfer.isValid) {
          this.saving = true

          await this.$http({
            url: '/api/financial/account-transfer',
            method: 'POST',
            data: this.formAccountTransfer,
          })
          this.showSidebar = false
          this.showSuccess({ message: this.$t('Depósito incluído com sucesso') })
          this.$emit('save', this.formAccountTransfer)
        } else {
          this.showInvalidDataMessage()
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
  },
}
</script>

<style></style>
